import React from "react";

import ReactGA from "react-ga4";
ReactGA.initialize("G-9GTJQTEGWH");
ReactGA.send("pageview");

const Home = () => {
  return <div className="main min-h-screen bg-black">Home</div>;
};

export default Home;

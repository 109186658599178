import React, { useState } from "react";
import FadeUp from "./Anime/FadeUp";
import Liner from "./Liner";

const TwoColImage = (props) => {
  const { image, headline, copy, flip, backgroundImage } = props;
  const [type, setType] = useState();

  React.useEffect(() => {
    const imageFormats = ["png", "jpg", "jpeg"];
    const videoFormats = ["mp4"];
    const format = image.substr(image.length - 3);

    if (videoFormats.includes(format)) {
      setType("video");
    } else if (imageFormats.includes(format)) {
      setType("image");
    }
  }, [image]);

  const renderImagery = () => {
    const imageFormats = ["png", "jpg", "jpeg"];
    const videoFormats = ["mp4"];
    const format = image.substr(image.length - 3);
    if (imageFormats.includes(format)) {
      if (backgroundImage) {
        return (
          <div
            style={{ backgroundImage: "url(" + image + ")" }}
            className="h-64 w-full md:flex-1 lg:basis-1/2 md:h-inherit bg-cover bg-center"
          ></div>
        );
      } else {
        return (
          <div className="w-full lg:w-1/2 bg-cover relative bg-cover h-full flex-1">
            <img className="h-auto m-auto" src={image} alt="" />
          </div>
        );
      }
    } else if (videoFormats.includes(format)) {
      return (
        <div className="w-full lg:w-1/2 bg-cover relative bg-cover h-full flex-1 items-center shadow-2xl ">
          <video
            className="w-full rounded-2xl"
            muted
            loop={true}
            autoPlay={true}
          >
            <source src={image} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
  };
  return (
    <div
      className={`bg-offBlack flex flex-wrap  xl:flex-nowrap ${
        type === "video" ? "items-center" : null
      }  ${flip ? "flex-row-reverse" : null}`}
    >
      <div className=" p-10 w-100 md:grow-1 lg:basis-1/2 lg:p-32 xl:p-48 2xl:p-64 3xl:80 m-auto">
        {headline ? (
          <FadeUp duration={1500}>
            <Liner />
            <h2 className="py-2 mt-3 mb-5 text-white font-bold text-2xl lg:text-5xl">
              {headline}
            </h2>
          </FadeUp>
        ) : null}
        {copy ? (
          <FadeUp duration={1500} delay={200}>
            <p className="text-gray-400 xl:text-xl flex-grow">{copy}</p>
          </FadeUp>
        ) : null}
      </div>
      {renderImagery()}
    </div>
  );
};

export default TwoColImage;

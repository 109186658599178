import React from "react";
import _ from "lodash";
import { getMediaSourceFromComponent } from "../utils";
import { SITE_URL } from "../config";
import FadeUp from "./Anime/FadeUp";

const IconList = (props) => {
  const { blocks } = props;

  const renderIcons = () => {
    return _.map(blocks, (block, index) => {
      return (
        <div
          key={index}
          className="w-full p-5 text-center md:w-1/2 lg:w-1/4 border-r border-gray-300 last:border-none "
        >
          <FadeUp autoplay={true} duration={750 * (index + 1)}>
            {block.Media ? (
              <img
                className="w-16 m-auto"
                src={getMediaSourceFromComponent(block.Media)}
              />
            ) : null}
            {block.Headline ? (
              <h3 className="font-bold md:text-2xl py-3 mt-4">
                {block.Headline}
              </h3>
            ) : null}
            {block.Copy ? (
              <p className="md:text-md font-serifz">{block.Copy}</p>
            ) : null}
          </FadeUp>
        </div>
      );
    });
  };

  return (
    <div className="mt-5 py-5 max-w-screen-xl m-auto">
      <div className="flex flex-wrap ">{renderIcons()}</div>
    </div>
  );
};

export default IconList;

import Testimonial from "./components/Testimonial";
import Panel from "./components/Panel";
import Collection from "./components/Collection";
import CardList from "./components/CardList";
import ImageGrid from "./components/ImageGrid";
import Hero from "./components/Hero";
import TwoColImage from "./components/TwoColImage";
import IconList from "./components/IconList";
import ReactMarkdown from "react-markdown";
import FadeIn from "./components/Anime/FadeIn";
import FadeUp from "./components/Anime/FadeUp";
import Liner from "./components/Liner";

export const getMediaSourceFromComponent = (component, size) => {
  let media;
  if (component) {
    if (size) {
      if (component.attributes) {
      } else {
        media = component.data.attributes.url;
      }
    } else if (component.attributes) {
      media = component.attributes.url;
    } else if (component.data.attributes.url !== null) {
      media = component.data.attributes.url;
    }

    if (media) return media;
  }
};

export const getMediaType = (component) => {
  let mime;
  if (component) {
    if (component.attributes) {
      mime = component.attributes.mime;
    } else {
      mime = component.data.attributes.mime;
    }
    if (mime === "video/mp4") {
      return "video";
    } else {
      return "image";
    }
  }
};

export const renderMedia = (media, classes, bg, size, options) => {
  if (getMediaType(media) === "video") {
    return (
      <video
        className={classes}
        muted
        loop={options.loop}
        autoPlay={options.autoPlay}
      >
        <source
          src={getMediaSourceFromComponent(media, size)}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    );
  } else if (bg) {
    return (
      <div
        className={classes}
        style={{
          backgroundImage: "url(" + getMediaSourceFromComponent(media) + ")",
        }}
      ></div>
    );
  } else {
    return (
      <img
        className={classes}
        src={
          size
            ? getMediaSourceFromComponent(media, size)
            : getMediaSourceFromComponent(media)
        }
        alt=""
      />
    );
  }
};

export function createMarkup(markup) {
  return { __html: markup };
}
export function returnComponent(type, component, index, classes) {
  switch (type) {
    case "text.text-block":
      return (
        <div className="bg-black">
          <div
            className={
              component.wrapperClasses
                ? component.wrapperClasses
                : "single-page max-w-4xl pt-52 pb-24 m-auto text-gray-500"
            }
            dangerouslySetInnerHTML={createMarkup(component.TextBlock)}
          />
        </div>
      );
    case "text.text-headline":
      return (
        <div className={` ${component.wrapperClasses} text-4xl`}>
          <FadeUp duration={1500} delay={200}>
            <div className={` ${component.cssClasses}`}>
              <Liner classes={"m-auto pb-4"} />
              {component.Copy}
            </div>
          </FadeUp>
        </div>
      );
    case "text.text-body":
      return (
        <ReactMarkdown className={classes} children={component.RichText} />
      );
    case "image.image-body":
      return (
        <div className={`${component.wrapperClasses}`}>
          <FadeIn duration={1500} delay={200}>
            {renderMedia(component.ImageBody, classes || component.cssClasses)}
          </FadeIn>
        </div>
      );
    case "panel.panel":
      return <Panel component={component} />;
    case "collection.collection":
      return (
        <Collection
          key={index}
          collectionType={component.CollectionType}
          collectionName={component.CollectionName}
        />
      );
    case "text.card-grid":
      return (
        <CardList
          key={index}
          headline={component.Headline}
          cards={component.CardBlock}
        />
      );
    case "text.testimonial":
      return <Testimonial key={index} testimonials={component.Testimonial} />;
    case "image.logo-grid":
      return <ImageGrid key={index} gridObj={component.Media.data} />;
    case "image.hero-banner":
      return (
        <Hero
          key={index}
          subheadline={component.Subheadline}
          headline={component.Headline}
          media={getMediaSourceFromComponent(component.Media)}
        />
      );
    case "image.two-column-with-image":
      return (
        <TwoColImage
          backgroundImage={component.BackgroundImage}
          key={index}
          flip={component.Flip}
          image={getMediaSourceFromComponent(component.Media)}
          headline={component.Headline}
          copy={component.Copy}
        />
      );
    case "image.icon-block":
      return <IconList key={index} blocks={component.Block} />;
    default:
      return null;
  }
}

import React from "react";
import send from "../assets/images/send.svg";

const OptinForm = (props) => {
  const { properties, label, placeholder, headline } = props;
  return (
    <div>
      {headline ? (
        <h4 className="pb-3 font-bold text-white uppercase tracking-widest">
          {headline}
        </h4>
      ) : null}
      <label className="block text-gray-700 text-sm font-bold mb-2"></label>
      <div className="flex justify-between shadow appearance-none  rounded w-full py-2 px-3 text-white border-2 border-gray-800 leading-tight ">
        <input
          className="flex-1 bg-transparent focus:outline-none bg-transparent focus:shadow-outline"
          id="username"
          type="text"
          placeholder={placeholder ? placeholder : ""}
        />
        <img className="w-5" src={send} alt="Send" />
      </div>
    </div>
  );
};

export default OptinForm;

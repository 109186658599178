import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import HubspotForm from "react-hubspot-form";
import { SITE_URL } from "../config";
import { getMediaSourceFromComponent } from "../utils";
import FadeIn from "./Anime/FadeIn";
import FadeUp from "./Anime/FadeUp";
import Offices from "./Offices";
import SocialIcons from "./SocialIcons";
import ReactGA from "react-ga4";

const Contact = () => {
  const [pageComponents, setPageComponents] = useState();

  const fetchPageData = () => {
    const pathname = window.location.pathname;

    axios
      .get(
        `${SITE_URL}/api/pages?filters[Slug][$eq]=${pathname}&populate=Body.Media&populate=Body.Block.Media&populate=Body.Testimonial&populate=Body.CardBlock.Media`
      )
      .then((res) => {
        let components = [];
        const pageData = res.data.data[0].attributes;
        const pageTitle = pageData.PageTitle;
        const pageBody = pageData.Body;

        console.log(pageData);

        _.map(pageBody, (body, i) => {
          components.push(body);
          if (i === pageBody.length - 1) {
            setPageComponents(components);
          }
        });
      })
      .catch((err) => console.log(err));
  };

  const getHeroBanner = () => {
    if (pageComponents) {
      return getMediaSourceFromComponent(pageComponents[0].Media);
    }
  };

  React.useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <div className="bg-black pt-32 h-full">
      <div className="w-100 flex flex-wrap p-3 lg:pb-12">
        <div className="w-full xl:w-1/3">
          <div className=" text-purple text-8xl xl:text-beefy 2xl:text-moreBeef uppercase font-bold p-7 text-center xl:text-left xl:flex flex-wrap">
            <FadeIn delay={200} duration={150}>
              <FadeUp duration={1500}>
                <div className="w-full">Drop</div>
              </FadeUp>
              <FadeUp duration={1500} delay={100}>
                <div className="w-full">Us</div>
              </FadeUp>
              <div className="w-full">
                <FadeUp duration={1500} delay={200}>
                  a
                </FadeUp>
              </div>
              <FadeUp duration={1500} delay={300}>
                <div className="w-full">Line</div>
              </FadeUp>
            </FadeIn>
          </div>
        </div>

        <div className="w-full lg:flex xl:w-2/3">
          <div className="w-full lg:w-3/5 border border-purple p-8">
            <FadeIn duration={500} delay={150}>
              <h3 className="text-white text-5xl">Email</h3>
            </FadeIn>
            <FadeUp duration={1200} delay={250}>
              <HubspotForm
                formId="4b4ab876-b6e2-437d-85fc-384dbb91f1d3"
                portalId="4135982"
                onSubmit={() => {
                  ReactGA.event({
                    category: "form",
                    action: "submission",
                    label: "contact page", // optional
                  });
                }}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </FadeUp>
          </div>
          <div className="w-full lg:h-full lg:w-2/5 bg-black border border-purple ">
            <div className="p-8  lg:h-3/5 border-b border-purple">
              <FadeIn duration={500} delay={150}>
                <h3 className="text-white text-5xl mb-4">HQs</h3>
              </FadeIn>

              <FadeUp duration={1350} delay={350}>
                <Offices />
              </FadeUp>
              <FadeUp duration={1400} delay={350}>
                <h3 className="text-white text-2xl pb-5 mt-5">Follow Us</h3>
                <SocialIcons />
              </FadeUp>
            </div>
            <div className={"h-2/5 w-full bg-cover bg-center relative"}>
              <FadeIn duration={500} delay={350}>
                <img
                  className="absolute top-0 left-0 h-full object-cover"
                  src={getHeroBanner()}
                  alt=""
                />
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

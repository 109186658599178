import _ from "lodash";
import React, { useState } from "react";
import { SITE_URL } from "../config";
import { getMediaSourceFromComponent } from "../utils";
import FadeLeft from "./Anime/FadeLeft";
import FadeUp from "./Anime/FadeUp";
import Card from "./Card";
import HeadlineCarousel from "./HeadlineCarousel";
import Liner from "./Liner";

const CardList = (props) => {
  const { cards, headline } = props;

  const renderCardList = () => {
    if (cards) {
      return _.map(cards, (card, index) => {
        if (card.Media.data === null) {
          console.log(card);
        }
        // console.log(card.Media[0].data);
        return (
          <FadeUp key={index} duration={1000 * (index + 1)}>
            <Card
              headline={card.Headline}
              copy={card.Copy}
              image={getMediaSourceFromComponent(card.Media)}
            />
          </FadeUp>
        );
      });
    }
  };
  return (
    <div className="bg-gray-100 py-24">
      <div className="max-w-screen-lg m-auto pb-16">
        {headline ? (
          <div className="w-full m-auto">
            <Liner classes="pb-3 m-auto w-1/3" />
            <h2 className="text-4xl font-bold text-center">{headline}</h2>
          </div>
        ) : null}
      </div>
      <div className="grid p-3 grid-cols-1 md:grid-cols-2 gap-10 max-w-screen-lg m-auto">
        {renderCardList()}
      </div>
    </div>
  );
};

export default CardList;

import React, { useState } from "react";
import FadeDown from "./Anime/FadeDown";

const Card = (props) => {
  const { image, copy, headline } = props;

  return (
    <div className="relative border border-gray-400 grid rounded-2xl h-full">
      <div className="w-full m-auto p-12 h-50">
        <h3 className="text-3xl pb-4 font-bold">{headline}</h3>
        <p className="text-xl mb-3 opacity-80">{copy}</p>
      </div>

      <FadeDown duration={500}>
        <div className="w-full p-5 bg-cover rounded-b-2xl bg-center">
          <img className="m-auto" src={image} alt="" />
        </div>
      </FadeDown>
    </div>
  );
};

export default Card;

import React, { useState } from "react";
import _ from "lodash";
import { SITE_URL } from "../config";
import FadeIn from "./Anime/FadeIn";

const ImageGrid = (props) => {
  const { gridObj } = props;

  const renderGrid = () => {
    if (gridObj) {
      return _.map(gridObj, (obj, index) => {
        const mediaUrl = obj.attributes.url;
        return (
          <div
            key={index}
            className="w-full md:w-1/4 lg:w-inheri md:p-12 lg:p-0 "
          >
            <FadeIn duration={250 * (index + 1)}>
              <img
                className="w-full lg:w-1/2 xl:w-1/4 xl:w-1/4 xl:p-0 m-auto p-12 md:p-4"
                src={mediaUrl}
                alt="Partner"
              />
            </FadeIn>
          </div>
        );
      });
    }
  };
  return (
    <div className="bg-black p-24">
      <h2 className="text-gray-400 mb-5 text-center uppercase tracking-widest text-2xl font-normal">
        Partners
      </h2>
      <div className={`flex flex-wrap items-center m-auto justify-center `}>
        {renderGrid()}
      </div>
    </div>
  );
};

export default ImageGrid;

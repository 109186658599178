import React from "react";
import Anime from "react-anime";
import FadeIn from "./Anime/FadeIn";
import FadeUp from "./Anime/FadeUp";

const Hero = (props) => {
  const [animationTrigger, setAnimationTrigger] = React.useState(false);
  const { media, headline, subheadline } = props;

  React.useEffect(() => {
    setAnimationTrigger(true);
  }, []);

  const renderMedia = () => {
    if (media.includes(".mp4")) {
      return (
        <video className="w-full" autoPlay muted loop>
          <source src={media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <img className="w-full" src={media} alt="" />;
    }
  };
  return (
    <FadeIn duration={600}>
      <div className="relative bg-black ">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          {headline ? (
            <FadeUp autoplay={animationTrigger}>
              <h1 className="text-white text-xl lg:text-5xl font-bold">
                {headline}
              </h1>
            </FadeUp>
          ) : null}
          {subheadline ? <p className="text-white">{subheadline}</p> : null}
        </div>
        {renderMedia()}
      </div>
    </FadeIn>
  );
};

export default Hero;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="text-center h-screen flex items-center flex-wrap justify-center">
      <div>
        <h1 className="w-full text-purple">404</h1>
        <p className="pb-5 w-full">
          If a page disappears on the web and nobody is around does it make a
          sound?
        </p>
        <Link
          className="bg-purple shadow-2xl py-2 px-3  text-white uppercase tracking-widest"
          to="/"
        >
          Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;

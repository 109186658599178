import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import { SITE_URL } from "../config";
import HorizontalCardList from "./HorizontalCardList";
import ProjectList from "./ProjectList";
import TeamList from "./TeamList";
const Collection = (props) => {
  const { collectionName, collectionType } = props;
  const [collection, setCollection] = useState([]);

  const fetchPageData = () => {
    if (collectionName) {
      axios
        .get(
          `${SITE_URL}/api/${collectionName}?populate=*&populate=Body.Media&populate=Media`
        )
        .then((res) => {
          setCollection(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const renderCollection = () => {
    if (collectionType === "card") {
      return <ProjectList collection={collection} />;
    } else if (collectionType == "teamCard") {
      return <TeamList collection={collection} />;
    }
    // else if (collectionType == "horizontalCard") {
    //   return <HorizontalCardList collection={collection} />;
    // }
  };

  React.useEffect(() => {
    if (collectionName) {
      fetchPageData();
    }
  }, [collectionName]);

  return <div>{renderCollection()}</div>;
};

export default Collection;

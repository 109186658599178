import _ from "lodash";
import React, { useState } from "react";
import {
  getMediaSourceFromComponent,
  getMediaType,
  renderMedia,
} from "../utils";
import FadeUp from "./Anime/FadeUp";
import FadeIn from "./Anime/FadeIn";

const TeamList = ({ collection }) => {
  const renderTeam = () => {
    const teamCollection = _.orderBy(collection, ["attributes.Order"], ["asc"]);
    console.log(teamCollection);
    return _.map(teamCollection, (item, index) => {
      const { attributes } = item;
      return (
        <FadeIn key={item.id} duration={500}>
          <div className="w-full" key={item.id}>
            <div>{renderMedia(attributes.Media, "w-full", null, "medium")}</div>

            <div className="p-4">
              {attributes.Name ? (
                <FadeUp duration={750}>
                  <h3 className="text-3xl font-bold text-purple">
                    {attributes.Name}
                  </h3>
                </FadeUp>
              ) : null}
              {attributes.Title ? (
                <FadeUp delay={150} duration={750}>
                  <h4 className="font-serifz text-xl text-gray-700">
                    {attributes.Title}
                  </h4>
                </FadeUp>
              ) : null}
            </div>
          </div>
        </FadeIn>
      );
    });
  };
  return (
    <div className="">
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 m-8">
        {renderTeam()}
      </div>
    </div>
  );
};

export default TeamList;

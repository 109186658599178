import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { renderMedia } from "../utils";
import FadeUp from "./Anime/FadeUp";

gsap.registerPlugin(ScrollTrigger);

const HorizontalCardList = ({ collection }) => {
  const boxRef = useRef();
  const slideRef = useRef();
  useEffect(() => {
    const container = boxRef.current;

    console.log(container.offsetWidth);

    const sections = gsap.utils.toArray(".slide-card");
    let maxWidth = 0;

    const getMaxWidth = () => {
      maxWidth = 0;
      sections.forEach((section) => {
        maxWidth += section.offsetWidth;
      });
    };

    getMaxWidth();

    ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

    createScrollTriggers();

    function createScrollTriggers() {
      gsap.to(sections, {
        x: () => `-${maxWidth - window.innerWidth}`,
        ease: { duration: 1.5, ease: "expo.out", y: -500 },
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 1,
          end: () => `+=${maxWidth}`,
          invalidateOnRefresh: true,
        },
      });

      sections.forEach((sct, i) => {
        ScrollTrigger.create({
          trigger: sct,
          start: () =>
            "top top-=" +
            (sct.offsetLeft - window.innerWidth / 2) *
              (maxWidth / (maxWidth - window.innerWidth)),
          end: () =>
            "+=" +
            sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
          toggleClass: { targets: sct, className: "active" },
        });
      });
    }
  });

  const renderCard = () => {
    if (collection) {
      return _.map(collection, (item, index) => {
        const card = item.attributes;
        const title = card.ProjectTitle;
        const blurb = card.Blurb;
        const findImage = _.find(card.Body, {
          __component: "image.hero-banner",
        });

        if (findImage) {
          return (
            <div
              key={item.id}
              className="relative md:w-1/2 lg:shrink-0 slide-card lg:w-screen lg:h-screen flex flex-wrap justify-center items-center"
              ref={slideRef}
            >
              <div className="w-full lg:w-64 text-white absolute bottom-10 left-10 lg:bottom-24 lg:left-24">
                <FadeUp
                  duration={index === 0 ? 0 : 1500}
                  delay={index === 0 ? 0 : 250}
                >
                  <h2 className="lg:text-6xl lg:pb-4 lg:font-bold">{title}</h2>
                </FadeUp>
                <FadeUp
                  duration={index === 0 ? 0 : 1500}
                  delay={index === 0 ? 0 : 500}
                >
                  <h3 className="lg:text-xl">{blurb}</h3>
                </FadeUp>
              </div>
              <div className="w-full">
                {renderMedia(
                  findImage.Media,
                  "w-full bg-center bg-cover h-64 lg:h-screen",
                  true
                )}
              </div>
            </div>
          );
        }
      });
    }
  };
  return (
    <div ref={boxRef} className="bg-black">
      <div className="md:flex flex-wrap lg:flex-nowrap bg-black">
        {renderCard()}
      </div>
    </div>
  );
};

export default HorizontalCardList;

import _ from "lodash";
import React, { useState } from "react";
import ProjectCard from "./ProjectCard";

const ProjectList = (props) => {
  const { collection } = props;
  const renderCards = () => {
    return _.map(collection, (item, index) => {
      const { Blurb, ProjectTitle } = item.attributes;
      return <ProjectCard key={index} card={item.attributes} />;
    });
  };
  return <div className="m-auto relative">{renderCards()}</div>;
};

export default ProjectList;

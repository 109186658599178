import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { SITE_URL } from "../config";
import _ from "lodash";
import { getMediaSourceFromComponent, returnComponent } from "../utils";
import { useLocation } from "react-router";
import SEO from "@americanexpress/react-seo";
import Contact from "./Contact";

export default function Page(props) {
  const location = useLocation();

  const { title, slug } = props;
  const [pageComponents, setPageComponents] = useState([]);
  const [seo, setSeo] = useState();

  const fetchPageData = () => {
    const pathname = window.location.pathname;

    axios
      .get(
        `${SITE_URL}/api/pages?filters[Slug][$eq]=${pathname}&populate=Body.Media&populate=Body.Block.Media&populate=Body.Testimonial&populate=Body.CardBlock.Media&populate=Body.PanelSpec&populate=Body.ImageBody&populate=SEO`
      )
      .then((res) => {
        let components = [];
        const pageData = res.data.data[0].attributes;
        const seo = pageData.SEO;
        const pageTitle = pageData.PageTitle;
        const pageBody = pageData.Body;

        _.map(pageBody, (body, i) => {
          components.push(body);
          if (i === pageBody.length - 1) {
            setSeo(seo);
            setPageComponents(components);
          }
        });
      })
      .catch((err) => console.log(err));
  };

  const renderPageComponents = () => {
    return _.map(pageComponents, (component, index) => {
      // console.log(component.__component);
      return returnComponent(component.__component, component, index);
    });
  };

  React.useEffect(() => {
    fetchPageData();
  }, [location]);

  return (
    <div>
      {seo && (
        <SEO
          title={seo.Title ? seo.Title : ""}
          description={seo.Description ? seo.Description : ""}
          keywords={seo.Keywords ? seo.Keywords.split(",") : ""}
          siteUrl={window.location.href}
          image={{
            src: "http://example.com/foo.jpg",
          }}
        />
      )}

      {renderPageComponents()}
      <Contact />
    </div>
  );
}

import React, { useState, useContext } from "react";
import { SiteSettings } from "../context/Settings";
import _ from "lodash";

const Offices = () => {
  const { offices } = useContext(SiteSettings);

  const renderLocationMenu = () => {
    if (offices.length > 0) {
      return _.map(offices, (menu) => {
        return (
          <div className="w-100" key={menu.id}>
            <h4 className="text-purple font-bold border-b table">
              {menu.Name}
            </h4>
            <ul className="text-left py-5 text-gray-400 text-lg">
              {menu.Email ? (
                <li>
                  <a className="border-b border-purple" mailto={menu.Email}>
                    {menu.Email}
                  </a>
                </li>
              ) : null}
              {menu.Phone ? (
                <li>
                  <a className="border-b border-purple" tel={menu.Phone}>
                    {menu.Phone}
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        );
      });
    }
  };

  return <div className="">{renderLocationMenu()}</div>;
};

export default Offices;

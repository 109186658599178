import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import { SITE_URL } from "../config";
import { returnComponent } from "../utils";
import NotFound from "./NotFound";

const Project = () => {
  const [project, setProject] = useState([]);

  const renderPageComponents = () => {
    return _.map(project.Body, (component, index) => {
      return returnComponent(
        component.__component,
        component,
        index,
        "max-w-screen-xl m-auto py-8"
      );
    });
  };

  const fetchPageData = () => {
    const { pathname } = window.location;
    if (pathname) {
      axios
        .get(
          `${SITE_URL}/api/projects?filters[Slug][$eq]=${pathname.replace(
            "/project",
            ""
          )}&populate=Body.Media&populate=Body.ImageBody`
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            setProject(res.data.data[0].attributes);
            console.log(res.data.data);
          } else {
            setProject(null);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  React.useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <div className="">{project ? renderPageComponents() : <NotFound />} </div>
  );
};

export default Project;

import _ from "lodash";
import React, { useState } from "react";
import { getMediaSourceFromComponent } from "../utils";
import { Link } from "react-router-dom";
import FadeIn from "./Anime/FadeIn";
import FadeUp from "./Anime/FadeUp";
import FadeDown from "./Anime/FadeDown";

const ProjectCard = ({ card, index }) => {
  const renderList = (categories) => {
    return _.map(categories, (cat) => {
      <li>{cat}</li>;
    });
  };

  const getHeroImage = () => {
    if (card.Body) {
      const imagePath = _.find(card.Body, { __component: "image.hero-banner" });
      return getMediaSourceFromComponent(imagePath.Media);
    }
    return getMediaSourceFromComponent(card.Media);
  };
  const isVideo = getHeroImage().includes(".mp4");

  console.log(isVideo);

  return (
    <div
      style={{ backgroundImage: "url(" + getHeroImage() + ")" }}
      className="w-full relative bg-center overflow-hidden bg-cover w-full h-screen bg-black"
    >
      {isVideo && (
        <video className="w-full" autoPlay muted loop>
          <source src={getHeroImage()} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className=" absolute w-full top-14 left-14">
        {card ? <FadeDown duration={1000 * (index + 1)}></FadeDown> : null}
      </div>
      <div className="absolute bottom-20 left-14 w-2/3 px-3">
        {card ? (
          <FadeUp duration={1100 * (index + 1)}>
            <div style={{ width: "32em" }}>
              <h2 className="text-white text-4xl pb-3 font-bold drop-shadow-md relative">
                {card.ProjectTitle}
              </h2>
              <p className="text-white w-100 text-2xl">{card.Blurb}</p>
            </div>
          </FadeUp>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectCard;

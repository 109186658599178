import axios from "axios";
import React, { useContext, useState } from "react";
import { SITE_URL } from "../config";
import _ from "lodash";
import MenuItem from "./MenuItem";
import { getMediaSourceFromComponent } from "../utils";
import OptinForm from "./OptinForm";
import { SiteSettings } from "../context/Settings";
import Offices from "./Offices";
import SocialIcons from "./SocialIcons";

const Footer = () => {
  const { socialMedia } = useContext(SiteSettings);
  const [footerMenu, setFooterMenu] = useState();
  // const [socialMenu, setSocialMenu] = useState();
  const [footerLogo, setFooterLogo] = useState();
  const [optinForm, setOptinForm] = useState();

  const fetchFooterData = () => {
    axios
      .get(
        `${SITE_URL}/api/footer?populate=FooterMenu&populate=SocialMenu.Media&populate=Logo&populate=Optin.HubspotProperty&populate=Offices`
      )
      .then((res) => {
        const data = res.data.data.attributes;
        setFooterMenu(data.FooterMenu);
        setOptinForm(data.Optin);
        setFooterLogo(data.Logo.data.attributes.url);
      });
  };

  React.useEffect(() => {
    fetchFooterData();
  }, []);

  const renderHeadline = (obj) => {
    const headline = _.find(obj, { __component: "text.headline" });
    if (headline) {
      return (
        <h4 className="pb-3 font-bold text-white uppercase tracking-widest">
          {headline.Title}
        </h4>
      );
    }
    return null;
  };

  const renderMenu = (menu, width) => {
    return _.map(menu, (menuItem, index) => {
      if (menuItem.__component !== "text.headline") {
        return (
          <div key={index} className={width}>
            <MenuItem
              classes={"text-gray-400"}
              title={menuItem.Title}
              url={menuItem.Url}
              image={
                menuItem.Icon
                  ? getMediaSourceFromComponent(menuItem.Icon)
                  : null
              }
            />
          </div>
        );
      }
    });
  };

  const renderOptin = () => {
    if (optinForm) {
      return (
        <OptinForm
          placeholder={optinForm.Placeholder}
          headline={optinForm.Headline}
          properties={optinForm.HubspotProperty}
        />
      );
    }
  };

  return (
    <div className="p-12 bg-black">
      <div className="flex max-w-screen-lg m-auto flex-wrap ">
        <div className="w-full  mb-3 md:w-1/4 text-center md:text-left">
          <img
            className="w-1/2 md:w-1/5 p-12 md:p-0 m-auto md:m-0 md:w-32"
            src={footerLogo}
            alt="ICON Virtual Production"
          />
          <div className="flex justify-center md:pt-12 md:justify-between">
            {renderHeadline(footerMenu, "w-full")}
          </div>
          {renderMenu(footerMenu)}
        </div>
        <div className="w-full md:w-1/3 m-auto md:m-0 md:m-0 md: text-center p-3">
          <Offices />
        </div>
        <div className="w-full md:w-1/3 m-auto md:m-0 py-3 text-center md:text-left">
          {renderOptin()}
          <div className="mt-12">
            {/* {renderHeadline(socialMenu)} */}
            <div className="flex justify-center md:justify-start">
              <SocialIcons />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import axios from "axios";
import React, { useState } from "react";
import { SITE_URL } from "../config";
import star from "../assets/images/star.svg";
import FadeDown from "./Anime/FadeDown";
import FadeIn from "./Anime/FadeIn";

const Testimonial = (props) => {
  const { testimonials } = props;
  const [activeTestimonial, setactiveTestimonial] = useState(0);

  const renderStars = () => {
    let stars = [];
    let i;
    for (i = 0; i < 5; i++) {
      stars.push(
        <FadeDown key={i} duration={500 * (i + 1)}>
          <img className="w-6" src={star} alt="Star" />
        </FadeDown>
      );
    }

    return stars;
  };

  const renderActiveTestimonial = () => {
    if (testimonials.length > 0) {
      const testimonial = testimonials[activeTestimonial];
      return (
        <div className="m-auto max-w-screen-sm text-center">
          <div className="flex justify-center mb-3">{renderStars()}</div>
          <FadeIn delay={300} duration={600}>
            <p className="text-2xl px-2">{testimonial.Quote}</p>
          </FadeIn>
          <FadeIn delay={600} duration={600}>
            <div className="mt-4 text-xl">{testimonial.Author}</div>
            <h5 className="uppercase text-gray-500">
              {testimonial.AuthorTitle}
            </h5>
          </FadeIn>
        </div>
      );
    }
  };

  return (
    <div className="m-0 p-5 md:p-24 bg-gray-100">
      {renderActiveTestimonial()}
    </div>
  );
};

export default Testimonial;

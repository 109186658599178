import { render } from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./components/Home";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Page from "./components/Page";
import Project from "./components/Project";
import { SettingsProvider } from "./context/Settings";
import Contact from "./components/Contact";
import ScrollToTop from "./hooks/ScrollToTop";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function App() {
  const location = useLocation();

  return (
    <>
      <Header />
      <ScrollToTop />
      <SettingsProvider>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes location={location}>
              <Route exact path="/" element={<Page title="Home" />} />
              <Route path=":page" element={<Page />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/project/:project" element={<Project />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
        <Footer />
      </SettingsProvider>
    </>
  );
}

const Root = () => <BrowserRouter><App /></BrowserRouter>; // prettier-ignore

render(<Root />, document.getElementById("root"));

import React, { useState, useEffect } from "react";
import Anime from "react-anime";
import { useInView } from "react-intersection-observer";

const Liner = ({ classes }) => {
  const { ref, inView } = useInView();
  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    if (inView) {
      setAutoplay(true);
    }
    if (!inView) {
      setAutoplay(false);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`flex justify-between w-1/3 items-center ${classes}`}
    >
      <Anime
        easing="easeOutQuint"
        autoplay={autoplay}
        width={["0%", "100%"]}
        duration={3000}
      >
        <div className="liner"></div>
      </Anime>
      <Anime
        easing="easeOutQuint"
        autoplay={autoplay}
        scale={["0", "1"]}
        duration={750}
        delay={1500}
      >
        <div className="liner-box"></div>
      </Anime>
    </div>
  );
};

export default Liner;

import axios from "axios";
import React, { useState, useCallback, useEffect } from "react";
import { SITE_URL } from "../config.js";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "./MenuItem.js";
import { getMediaSourceFromComponent } from "../utils.js";
import FadeIn from "./Anime/FadeIn.js";
import Lottie from "react-lottie";
import * as animationData from "./icon-animate.json";
import ReactGA from "react-ga4";
ReactGA.initialize("G-9GTJQTEGWH");
ReactGA.send("pageview");

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [siteLogo, setSiteLogo] = useState();
  const [mainMenu, setMainMenu] = useState([]);
  const [ctaButton, setCtaButton] = useState(null);
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const location = useLocation();

  const defaultOptions = {
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    loop: false,
    animationData: animationData,
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentLocation(location.pathname);
  }, [location]);

  React.useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = () => {
    axios
      .get(`${SITE_URL}/api/main-menu?populate=*`)
      .then((res) => {
        const data = res.data.data.attributes;
        setCtaButton(data.NavCTA[0]);
        setMainMenu(data.body);
        setSiteLogo(data.Logo.data.attributes.url);
      })
      .catch((err) => console.log(err));
  };

  const menuClickedCallback = () => {
    if (showMobileMenu) {
      setShowMobileMenu(false);
      return;
    }
  };
  const renderMainMenu = () => {
    if (mainMenu.length > 0) {
      return _.map(mainMenu, (menuItem, index) => {
        return (
          <FadeIn key={index} duration={250 * (index + 1)}>
            <MenuItem
              currentLocation={currentLocation}
              setShowMobileMenu={() => menuClickedCallback()}
              classes="py-2 px-4 block cursor-pointer text-white uppercase tracking-widest"
              title={menuItem.Title}
              url={menuItem.Url}
              image={
                menuItem.Media
                  ? getMediaSourceFromComponent(menuItem.Media)
                  : null
              }
            />
          </FadeIn>
        );
      });
    }
  };

  return (
    <header>
      {/* Mobile Menu */}
      <div
        className={`${
          showMobileMenu ? "block" : "hidden"
        } fixed transition-all lg:hidden bg-black pt-24 h-full w-full z-40 opacity-90 top-0`}
      >
        <div className="p-5 text-4xl">{renderMainMenu()}</div>
      </div>

      <div className={`w-full fixed bg-black md:hidden p-3 z-50 `}>
        <div
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          className="absolute right-0 top-0 h-full w-16 text-white flex items-center justify-center bg-purple"
        >
          <i className="far fa-bars"></i>
        </div>
        <div className="flex">
          <div className="w-full">
            <FadeIn duration={1000}>
              <Link to="/">
                <div id="lottie-logo">
                  <Lottie options={defaultOptions} width={150} />
                </div>
              </Link>
            </FadeIn>
          </div>
        </div>
      </div>

      <div
        className={`p-4 absolute transition  w-full z-50 hidden md:flex ${
          showFixedHeader ? "bg-black" : null
        }`}
      >
        {/* <div className="bg-gradient-to-b from-black h-full z-0 absolute top-0 left-0 w-full"></div> */}

        <nav className="flex items-center w-full relative justify-between">
          <div className="">
            <FadeIn duration={1000}>
              <Link to="/">
                <div id="lottie-logo">
                  <Lottie options={defaultOptions} width={250} />
                </div>
                {/* <img
                  className="w-32"
                  src={siteLogo}
                  alt="ICON Virtual Production"
                /> */}
              </Link>
            </FadeIn>
          </div>
          <div className=" flex justify-between">{renderMainMenu()}</div>
          <div className=" flex justify-end">
            {ctaButton ? (
              <FadeIn duration={1100}>
                <div className="btn relative p-2">
                  <Link
                    className="relative bg-purple rounded-full shadow-2xl py-2 hover:text-white px-5 text-white uppercase tracking-widest"
                    to={ctaButton.Url}
                  >
                    {ctaButton.Title}
                  </Link>
                </div>
              </FadeIn>
            ) : null}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;

import _ from "lodash";
import React, { useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { getMediaSourceFromComponent, renderMedia } from "../utils";
import FadeIn from "./Anime/FadeIn";

gsap.registerPlugin(ScrollTrigger);

const Panel = ({ component }) => {
  React.useEffect(() => {
    const videos = gsap.utils.toArray("video");

    console.log(videos);

    if (videos) {
    }

    // videos.forEach(function (video, i) {
    //   ScrollTrigger.create({
    //     trigger: video,
    //     scroller: ".appVideos",
    //     start: "top center",
    //     end: "bottom center",
    //     markers: true,
    //     onEnter: () => video.play(),
    //     onEnterBack: () => video.play(),
    //     onLeave: () => video.pause(),
    //     onLeaveBack: () => video.pause(),
    //   });
    // });
  }, []);

  const renderSpecs = () => {
    return _.map(component.PanelSpec, (spec, index) => {
      return (
        <FadeIn duration={50 * (index + 1)}>
          <li className="flex justify-between border-b border-gray-900 py-2">
            <span className="text-gray-500">{spec.SpecName}</span>
            <span className="text-white text-right">{spec.SpecValue}</span>
          </li>
        </FadeIn>
      );
    });
  };
  return (
    <div className="bg-black p-24" style={{ background: "#010001" }}>
      <div className="flex items-center flex-wrap max-w-screen-3xl m-auto">
        <div className="w-full lg:w-1/2 xl:w-3/5 xl:p-24  ">
          <FadeIn duration={500}>
            {renderMedia(component.Media.data[0], "w-full", null, null, {
              autoPlay: 1,
            })}
          </FadeIn>
        </div>
        <div className="w-full lg:w-1/2 xl:w-2/5  bg-black">
          <div className="p-5">
            <h3 className="text-white text-5xl pb-5">Specs</h3>
            {renderSpecs()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;

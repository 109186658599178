import { Link } from "react-router-dom";
import React from "react";

const MenuItem = (props) => {
  const { url, image, title, classes, setShowMobileMenu, currentLocation } =
    props;

  return (
    <div
      className={`px-3 ${currentLocation === url && "active-menu"}`}
      onClick={() => setShowMobileMenu()}
    >
      <Link className={classes + " z-50"} to={url}>
        {image ? <img className="w-5" src={image} alt={title} /> : null}
        {title ? title : null}
      </Link>
    </div>
  );
};

export default MenuItem;

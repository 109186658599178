import React, { useEffect, useState, createContext } from "react";
import axios from "axios";
import _ from "lodash";
import { SITE_URL } from "../config";
import { getMediaSourceFromComponent } from "../utils";
export const SiteSettings = createContext();

export const SettingsProvider = ({ children }) => {
  const [siteLogo, setSiteLogo] = useState();
  const [offices, setOffices] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);

  const fetchData = () => {
    axios
      .get(
        `${SITE_URL}/api/site-setting?populate=PrimaryLogo&populate=Locations&populate=SocialMedia.Icon`
      )
      .then((res) => {
        const data = res.data.data.attributes;
        setSiteLogo(getMediaSourceFromComponent(data.PrimaryLogo));
        setOffices(data.Locations);
        setSocialMedia(data.SocialMedia);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SiteSettings.Provider value={{ siteLogo, offices, socialMedia }}>
      {children}
    </SiteSettings.Provider>
  );
};

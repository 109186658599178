import React, { useContext, useState } from "react";
import { SiteSettings } from "../context/Settings";
import _ from "lodash";
import axios from "axios";
import MenuItem from "./MenuItem";
import { getMediaSourceFromComponent } from "../utils";

const SocialIcons = () => {
  const { socialMedia } = useContext(SiteSettings);

  const renderMenu = () => {
    if (socialMedia) {
      return _.map(socialMedia, (menuItem, index) => {
        if (menuItem.__component !== "text.headline") {
          return (
            <div key={index} className="w-10">
              <MenuItem
                classes={"text-gray-400"}
                title={menuItem.Title}
                url={menuItem.Url}
                image={
                  menuItem.Icon
                    ? getMediaSourceFromComponent(menuItem.Icon)
                    : null
                }
              />
            </div>
          );
        }
      });
    }
  };

  return <div className="flex">{renderMenu()}</div>;
};

export default SocialIcons;
